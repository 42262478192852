h1 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 5vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  color: white;
  background-color: #000000;
  font-size: calc(10px + 2vmin);
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
}

.App-body {
  color: black;
  background-color: #eeeeee;
  font-size: calc(10px + 1vmin);
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
}

.App-link {
  color: #0088cc;
}

.App-chart {
  width: 50vmin;
  height: 50vmin;
}

.App-topnav {
  overflow: hidden;
  background-color: #666666;
}
.App-topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.App-topnav a:hover {
  background-color: #979797;
  color: black;
}

.App-topnav a.active {
  background-color: #979797;
  color: white;
}

.App-topnav .icon {
  display: none;
}

table, tbody, tr, th, td {
  border: 1px solid black;
  font-size: medium;
  table-layout: auto;
  width: 100%;
}

.etaoTable {
  margin-left: 50px;
  margin-right: 50px;
}

