/* CSS file for Login.js */

.App-login {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .App-login form {
    width: 100%;
    background-color: #979797;
    margin: 20px 20px; 
    padding: 20px 20px;
  }
  
  .App-login button {
    margin-top: 20px;
  }